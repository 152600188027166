import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import ClientsAPIHelper from "api/clients-api.helper";
import ConnectAPIHelper from "api/connect-api-helper";
import MMPage from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Client, clientName } from "store/data/client/client";
import MMAddressInfo from "./dossier/address-info";
import MMCareInfo from "./dossier/care-info";
import MMCommunicationInfo from "./dossier/communication-info";
import MMContactInfo from "./dossier/contact-info";
import MMIdInfo from "./dossier/id-info";
import MMInsuranceInfo from "./dossier/insurance-info";
import MMPersonalInfo from "./dossier/personal-info";

function MMClientDossier() {
  const [buttonsToShow, setButtonsToShow] = useState([]);

  const api: ConnectAPIHelper = new ConnectAPIHelper();
  const clientApi: ClientsAPIHelper = new ClientsAPIHelper();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [client, setClient] = useState<Client | null>(null);
  const [referralId, setReferralId] = useState<number>();

  const [isClientEditAllowed, setIsClientEditAllowed] =
    useState<boolean>(false);

  useEffect(() => {
    const loadClient = async (clientId: number) => {
      const result: ApiResponse = await clientApi.getClient(clientId);
      if (result && result.status === ApiResponseStatus.OK) {
        delete result.result.vlot_data;
        setClient(result.result);
      } else {
        setClient(null);
        return;
      }

      if (result && result.result) {
        //todo: figure out paging  / load more interface for referrals.
        const referralsResult: ApiResponse = await api.getReferrals(
          { client_code: result.result.client_code },
          5
        );
        if (
          referralsResult &&
          referralsResult.status === ApiResponseStatus.OK
        ) {
          if (referralsResult.result.length > 0 && !referralId) {
            setReferralId(referralsResult.result.id);
          } else {
            setReferralId(undefined);
          }
        }
      }
    };

    if (clientId) {
      loadClient(parseInt(clientId as string));
    } else {
      console.log("no client id?", clientId);
    }
  }, [clientId]);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any = [];

      buttons.push(
        <EuiButton
          aria-label={txt.get("clients.page_title")}
          size="s"
          fill={true}
          style={{ width: "40px", minInlineSize: "40px" }}
          iconType="faceHappy"
          onClick={() => navigate("/clients")}
        />
      );

      if (client) {
        buttons.push(
          <EuiButton
            aria-label={txt.get("clients.page_title")}
            size="s"
            onClick={() => navigate(`/clients/${client.id}`)}
          >
            {clientName(client, true)} #{client.client_code}
          </EuiButton>
        );
      }

      setButtonsToShow(buttons);

      setIsClientEditAllowed(
        false &&
          (await AuthenticationHelper.hasPermission([
            "client_details#edit_all",
            "client_details#edit_org",
            "client_details#edit",
          ]))
      );
    };

    setInterfaceForPermissions();
  }, [client]);

  return (
    <MMPage
      title={txt.get("clients.client.page_title")}
      topActions={buttonsToShow}
      sideActions={[
        client ? (
          <EuiFlexItem style={{ margin: "auto", padding: "10px" }}>
            <EuiText size="xs" color="#999999">
              {txt.get(
                "orders.order.client_last_refresh",
                DateHelper.toDateHM(client.updated_at)
              )}
            </EuiText>
          </EuiFlexItem>
        ) : (
          <></>
        ),
      ]}
      hideTitle={true}
      className="subtle-editing"
    >
      <EuiSpacer />

      <EuiFlexGroup gutterSize="xl">
        <EuiFlexItem grow={3}>
          <MMPersonalInfo client={client} isEditable={isClientEditAllowed} />
          <EuiSpacer />
          <MMContactInfo client={client} isEditable={isClientEditAllowed} />
          <EuiSpacer />
          <MMAddressInfo client={client} isEditable={isClientEditAllowed} />
          <EuiSpacer />
          <MMInsuranceInfo client={client} isEditable={isClientEditAllowed} />
          <EuiSpacer />
          <MMIdInfo client={client} isEditable={isClientEditAllowed} />
        </EuiFlexItem>

        <EuiFlexItem grow={3}>
          {clientId ? <MMCommunicationInfo client={client} /> : <></>}
        </EuiFlexItem>
        <EuiFlexItem grow={3}>
          {clientId ? (
            <MMCareInfo
              clientId={parseInt(clientId as string)}
              client={client}
            />
          ) : (
            <></>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </MMPage>
  );
}

export default MMClientDossier;
