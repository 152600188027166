export enum OrderConsentType {
  Submit = "SUBMIT",
  Deliver = "DELIVER",
}

export interface OrderConsent {
  id: number | null;
  client_code: string;
  practitioner: number | null;
  type: OrderConsentType;
  consents: boolean;
  consents_to: string;
  consented_at: Date;
}
