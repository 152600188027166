import {
  addBusinessDays,
  addDays,
  addHours,
  formatDistanceToNowStrict,
  intervalToDuration,
  isBefore,
  isFuture,
  isSaturday,
  isSunday,
  isWithinInterval,
  Locale,
  subDays,
} from "date-fns";

import { enUS, nl } from "date-fns/locale";

import { formatInTimeZone } from "date-fns-tz";

//dutch holidays
const holidays: string[] = [
  "2019-01-01", //	Nieuwjaarsdag
  "2019-04-19", //	Goede vrijdag
  "2019-04-21", //	1e Paasdag
  "2019-04-22", //	2e Paasdag
  "2019-04-27", //	Koningsdag
  "2019-05-05", //	Bevrijdingsdag
  "2019-05-30", //	Hemelvaartsdag
  "2019-06-09", //	1e Pinksterdag
  "2019-06-10", //	2e Pinksterdag
  "2019-12-25", //	1e Kerstdag
  "2019-12-26", //	2e Kerstdag
  "2019-12-31", //	Oudejaarsdag
  "2020-01-01", //	Nieuwjaarsdag 2020
  "2020-05-05", //	Bevrijdingsdag 2020
  "2020-05-21", //	Hemelvaart 2020
  "2020-05-31", //	1e Pinksterdag 2020
  "2020-04-10", //	Goede vrijdag 2020
  "2020-04-12", //	1e Paasdag 2020
  "2020-04-13", //	2e Paasdag 2020
  "2020-04-27", //	Koningsdag 2020
  "2020-06-01", //	2e Pinksterdag 2020
  "2020-12-25", //	1e Kerstdag 2020
  "2020-12-26", //	2e Kerstdag 2020
  "2020-01-01", //	Nieuwjaarsdag 2020
  "2020-05-05", //	Bevrijdingsdag 2020
  "2020-05-21", //	Hemelvaart 2020
  "2020-05-31", //	1e Pinksterdag 2020
  "2020-04-10", //	Goede vrijdag 2020
  "2020-04-12", //	1e Paasdag 2020
  "2020-04-13", //	2e Paasdag 2020
  "2020-04-27", //	Koningsdag 2020
  "2020-06-01", //	2e Pinksterdag 2020
  "2020-12-25", //	1e Kerstdag 2020
  "2020-12-26", //	2e Kerstdag 2020
  "2021-01-01", //	Nieuwjaarsdag 2021
  "2021-05-13", //	Hemelvaart 2021
  "2021-05-23", //	1e Pinksterdag 2021
  "2021-05-24", //	2e Pinksterdag 2021
  "2021-04-02", //	Goede vrijdag 2021
  "2021-04-04", //	1e Paasdag 2021
  "2021-04-05", //	2e Paasdag 2021
  "2021-04-27", //	Koningsdag 2021
  "2021-12-25", //	1e Kerstdag 2021
  "2021-12-26", //	2e Kerstdag 2021
  "2022-01-01", //	Nieuwjaar
  "2022-04-17", //	1e Paasdag (Pasen)
  "2022-04-18", //	Tweede paasdag
  "2022-04-27", //	Koningsdag
  "2022-05-26", //	Helmelvaartsdag
  "2022-06-05", //	1e Pinksterdag
  "2022-06-06", //	Tweede pinksterdag
  "2022-12-25", //	1e Kerstdag
  "2022-12-26", //	2e Kerstdag
  "2023-01-01", //	Nieuwjaar
  "2023-04-09", //	1e Paasdag (Pasen)
  "2023-04-10", //	Tweede paasdag
  "2023-04-27", //	Koningsdag
  "2023-05-18", //	Helmelvaartsdag
  "2023-05-28", //	1e Pinksterdag
  "2023-05-29", //	Tweede pinksterdag
  "2023-12-25", //	1e Kerstdag
  "2023-12-27", //	2e Kerstdag
  "2024-01-01", //	Nieuwjaar
  // "2024-03-29", //	1e Paasdag (Pasen)
  "2024-04-01", //	Tweede paasdag
  "2024-04-27", //	Koningsdag
  "2024-05-09", //	Helmelvaartsdag
  // "2024-05-19", //	1e Pinksterdag
  "2024-05-20", //	Tweede pinksterdag
  "2024-12-25", //	1e Kerstdag
  "2024-12-26", //	2e Kerstdag
];

class DateHelper {
  private static locale(lang?: string) {
    return lang === "nl" ? nl : lang === "en" ? enUS : nl;
  }

  static timezone() {
    return "Europe/Amsterdam";
  }

  static format(date: Date, format: string, locale?: Locale) {
    return formatInTimeZone(date, DateHelper.timezone(), format, {
      locale: locale || DateHelper.locale(),
    });
  }

  static parseDate(value?: string | Date | null | undefined) {
    let result = null;
    try {
      if (value instanceof Date) {
        result = value;
      } else if (value == null || value === undefined) {
        result = new Date();
      } else {
        result = new Date(value);
      }
    } catch (e) {
      console.error("DateHelper.parseDate error", e, value);
      result = new Date();
    }
    return result;
  }

  static toDateTimeSortable(value?: string | Date | null | undefined) {
    return DateHelper.format(
      DateHelper.parseDate(value),
      "yyyy-MM-dd HH:mm:ss"
    );
  }

  static toISO(value?: string | Date | null | undefined) {
    return DateHelper.format(
      DateHelper.parseDate(value),
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
    );
  }

  static toDayDateTime(value?: string | Date | null | undefined) {
    return DateHelper.format(
      DateHelper.parseDate(value),
      "EEE dd-MMM-yyyy HH:mm",
      enUS
    );
  }
  static toDateTime(value?: string | Date | null | undefined) {
    return DateHelper.format(
      DateHelper.parseDate(value),
      "dd-MM-yyyy HH:mm:ss"
    );
  }

  static toDateHM(value?: string | Date | null | undefined) {
    return DateHelper.format(DateHelper.parseDate(value), "dd-MM-yy HH:mm");
  }

  static toDateWorded(
    value?: string | Date | null | undefined,
    locale?: string
  ) {
    return DateHelper.format(
      DateHelper.parseDate(value),
      "d MMMM",
      locale === "nl" ? nl : locale === "en" ? enUS : nl
    );
  }

  static toDateShort(value?: string | Date | null | undefined) {
    return DateHelper.format(DateHelper.parseDate(value), "dd-MM");
  }

  static toTime(value?: string | Date | null | undefined) {
    return DateHelper.format(DateHelper.parseDate(value), "HH:mm");
  }

  static toSortable(value?: string | Date | null | undefined) {
    // log.info("toSortable", value);
    return DateHelper.format(DateHelper.parseDate(value), "yyyyMMdd");
  }

  static toDateSql(value?: string | Date | null | undefined) {
    // log.info("toSortable", value);
    return DateHelper.format(DateHelper.parseDate(value), "yyyy-MM-dd");
  }

  static toDateTimeSql(value?: string | Date | null | undefined) {
    // log.info("toSortable", value);
    return DateHelper.format(
      DateHelper.parseDate(value),
      "yyyy-MM-dd HH:mm:ss"
    );
  }

  static toDate(value?: string | Date | null | undefined) {
    return DateHelper.format(DateHelper.parseDate(value), "dd-MM-yy");
  }

  static toDateFullYear(value?: string | Date | null | undefined) {
    return DateHelper.format(DateHelper.parseDate(value), "dd-MM-yyyy");
  }

  static tight(value?: string | Date | null | undefined) {
    return DateHelper.format(DateHelper.parseDate(value), "dd-MM-yy HH:mm:ss");
  }

  static subDays(days: number, value?: string | Date | null | undefined) {
    return subDays(DateHelper.parseDate(value), days);
  }

  static addBusinessDays(
    days: number,
    value?: string | Date | null | undefined,
    includeNonBusinessHolidays: boolean = true
  ) {
    const startDate = DateHelper.parseDate(value);
    let endDate = addBusinessDays(startDate, days);

    if (includeNonBusinessHolidays) {
      for (let i = 0; i < holidays.length; i++) {
        const date: Date = DateHelper.parseDate(holidays[i]);
        if (
          !(isSunday(date) || isSaturday(date)) &&
          isWithinInterval(date, { start: startDate, end: endDate })
        ) {
          endDate = addBusinessDays(endDate, 1);
        }
      }
    }

    return endDate;
  }

  static addDays(days: number, value?: string | Date | null | undefined) {
    return addDays(DateHelper.parseDate(value), days);
  }

  static ago(
    value?: string | Date | null | undefined,
    lang?: string,
    toDateAfterDay: boolean = false
  ) {
    return toDateAfterDay && this.olderThan(24, value)
      ? this.toDateHM(value)
      : formatDistanceToNowStrict(DateHelper.parseDate(value), {
          addSuffix: true,
          locale: DateHelper.locale(lang),
        });
  }

  static isBefore(date: Date, toCompare?: string | Date | null | undefined) {
    return isBefore(date, DateHelper.parseDate(toCompare));
  }

  static old(
    value?: string | Date | null | undefined,
    lang?: string,
    toDateAfterDay: boolean = false
  ) {
    return toDateAfterDay && this.olderThan(24, value)
      ? this.toDateHM(value)
      : formatDistanceToNowStrict(DateHelper.parseDate(value), {
          addSuffix: false,
          locale: DateHelper.locale(lang),
        });
  }

  static olderThan(hours: number, value?: string | Date | null | undefined) {
    return !isFuture(addHours(DateHelper.parseDate(value), hours));
  }

  static durationWorded(
    from?: string | Date | null | undefined,
    until?: string | Date | null | undefined,
    worded: boolean = false
  ) {
    return this.duration(from, until, true);
  }

  static duration(
    from?: string | Date | null | undefined,
    until?: string | Date | null | undefined,
    worded: boolean = false
  ) {
    from = DateHelper.parseDate(from);
    until = DateHelper.parseDate(until);
    const duration = intervalToDuration({
      start: from.getTime(),
      end: until.getTime(),
    });

    // let result = worded
    //   ? formatDuration(duration, { format: ["minutes"] }).replace(
    //       "minutes",
    //       "min"
    //     )
    //   : `${String(duration.hours).padStart(2, "0")}:${String(
    //       duration.minutes
    //     ).padStart(2, "0")}`;
    let result = "";

    if (worded) {
      if (duration.hours) {
        result = `${String(duration.hours)} hrs.  `;
      }
      if (duration.minutes) {
        result += `${String(duration.minutes)} min.  `;
      }
    } else {
      result = `${String(duration.hours).padStart(2, "0")}:${String(
        duration.minutes
      ).padStart(2, "0")}`;
    }
    // ? `${
    //     duration.hours && duration.hours > 0
    //       ? `${String(duration.hours)} hrs. ${
    //           duration.minutes && duration.minutes > 0
    //             ? `${String(duration.minutes)} mins.`
    //             : ""
    //         }`
    //       : "s"
    //   }`
    // : ;

    return result;
  }
}

export default DateHelper;
